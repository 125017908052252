// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers/

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
import { addPropertyControls, ControlType } from "framer"

export default function Search_container(props) {
    // This is a React component containing an Example component
    // - Replace <Example /> with your own code
    // - Find inspiration: https://www.framer.com/developers/

    return (
        <div
            id="cecsearchcontainer"
            data-colorscheme={props.colorScheme}
            style={containerStyle}
        ></div>
    )
}

Search_container.defaultProps = {
    colorScheme: "light",
}

addPropertyControls(Search_container, {
    colorScheme: {
        type: ControlType.String,
        title: "Color Scheme",
    },
})

// Styles are written in object syntax
// Learn more: https://reactjs.org/docs/dom-elements.html#style
const containerStyle = {
    height: "auto",
    maxWidth: "1100px",
    minWidth: "340px",
}
